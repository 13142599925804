<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="Doctors" model-title="Doctors"
          :model-api="['models','Master','Doctors']" model-key="doctorCode" :headers="headers" importable searchable>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container>
              <v-row>
                <v-col cols="2">
                  <v-text-field :rules="[rules.require()]" v-model="data.doctorCode" label="รหัสแพทย์" :disabled="!isCreating"></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field :rules="[rules.require()]" v-model="data.nameTH" label="ชื่อแพทย์ (ภาษาไทย)"></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field :rules="[rules.require()]" v-model="data.nameEN" label="ชื่อแพทย์ (ภาษาอังกฤษ)"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-master-field :rules="[rules.require()]" v-model="data.doctorType" label="ชนิดแพทย์"
                    :groupKey="$store.getters.getGroupKey('doctorType')"
                  ></v-master-field>
                </v-col>
                <v-col cols="4">
                  <v-master-field :rules="[rules.require()]" v-model="data.specialty" label="สาขาความเชี่ยวชาญ"
                    :groupKey="$store.getters.getGroupKey('doctorSpecialty')"
                  ></v-master-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="data.mainHospital" label="สถานที่ทำงานประจำ"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field :rules="[rules.require()]" v-model="data.licenseNo" label="เลขใบประกอบวิชาชีพเวชกรรม"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field :rules="[rules.require()]" v-model="data.personId" label="เลขประจำตัวประชาชน"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="data.defaultTaxId" label="เลขประจำตัวผู้เสียภาษี"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field :rules="[rules.require()]"  v-model="data.telephone" label="เบอร์โทรศัพท์ติดต่อ"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="data.email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="data.note" label="Note" auto-grow></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.doctorType="props">
            <v-label-master group-key="doctorType" :item-code="props.item.doctorType"></v-label-master>
          </template>
          <template v-slot:item.specialty="props">
            <v-label-master group-key="doctorSpecialty" :item-code="props.item.specialty"></v-label-master>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสแพทย์',
        value: 'doctorCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อแพทย์ (ภาษาไทย)',
        value: 'nameTH',
        class: 'body-2'
      },
      {
        text: 'ชนิดแพทย์',
        value: 'doctorType',
        class: 'body-2'
      },
      {
        text: 'สาขาความเชี่ยวชาญ',
        value: 'specialty',
        class: 'body-2'
      },
      {
        text: 'เลขใบประกอบวิชาชีพเวชกรรม',
        value: 'licenseNo',
        class: 'body-2'
      },
      {
        text: 'เบอร์โทรศัพท์ติดต่อ',
        value: 'telephone',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
}
</script>

<style lang="css">
</style>
